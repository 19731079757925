import React, { useState, useEffect, useContext} from 'react';
import { Text, SafeAreaView, View, TouchableOpacity, StyleSheet, FlatList, RefreshControl, Image} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useIsFocused } from "@react-navigation/native";
import Loading from '../elements/Loading';
import { Context } from '../Context';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";

const ScoreDetailsScreen = (props) => {

    const {user, heat} = useContext(Context);

    const id = props.route.params.id;

    const [player, setPlayer] = useState(null);
    const [events, setEvents] = useState([]);
    const [score, setScore] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [isOpen, setIsOpen] = useState(false)

    const isFocused = useIsFocused();

    const fetchData = async () => {

        let response;
        let results;

        response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getUser',
                id: id
            })
        });

        results = await response.json();
        setPlayer(results.user);

        response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getEventInfo',
                id: id
            })
        });

        results = await response.json();
        setScore(results.user.score);
        setEvents(results.user.events);
        setRefreshing(false);        
    };

    useEffect(() => {
        fetchData();

        return () => {
            setPlayer(null);
            setScore(null);
            setEvents([]);
            setRefreshing(false);   
        }
    }, [isFocused, id]);

    const refeshData = () => {
        setRefreshing(true);
        fetchData();
    };

    const scorePressed = (event) => {
        if(parseInt(user.admin)){
            props.navigation.navigate('Edit Score', { event: event })
        } else {
            return false;
        }
    }


    const renderItem = ({item}) => {

        let isCurHeat = heat === item.heat

        let color = isCurHeat ? '#169B62' : (item.heat <=  heat ? 'grey' : 'black');
        let heatColor = isCurHeat ? '#169B62' : (item.heat <=  heat ? 'grey' : "#FF883E");

        return (
            <View style={styles.row}>
                <Text style={{ fontSize: 20, color: isCurHeat ? color : heatColor, width: 50}}>
                    <FontAwesome name="fire" size={25} color={heatColor} /> 
                    {item.heat}
                </Text>
                <View>
                    <View style={styles.nameContainer}>
                        <Text style={[styles.nameTxt, {color: color, fontSize: 21, fontWeight: 'bold'}]} numberOfLines={1} ellipsizeMode="tail">
                            {item.event ? item.event : 'Bye'}
                        </Text>            
                    </View>
                    <View style={styles.nameContainer}>
                        <Text style={[styles.nameTxt, {color: color}]} numberOfLines={1} ellipsizeMode="tail">
                            {item.event ? item.teammate : null}
                        </Text>            
                    </View>
                    <View style={styles.nameContainer}>
                        <Text style={[styles.nameTxt, {color: color, width: '100%'}]} numberOfLines={1} ellipsizeMode="tail">
                            {item.opponents ? 'vs. ' + item.opponents[0].concat(' & ',item.opponents[1]) : null }
                        </Text>            
                    </View>
                </View>
                <TouchableOpacity onPress={() => scorePressed(item)}>
                    <Text style={{fontSize: 18, fontWeight: 'bold',color: color}} > {item.score}</Text>
                </TouchableOpacity>
                
            </View>
        );
      }

    const styles = StyleSheet.create({
        tasks:{
          flex:1,
          width: '100%'
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            borderColor: '#DCDCDC',
            backgroundColor: '#fff',
            borderBottomWidth: 1,
            padding: 10,
            minHeight: 90,
            marginBottom: 3
          },
          pic: {
            borderRadius: 30,
            width: 60,
            height: 60,
          },
          nameContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 250,
          },
          nameTxt: {
            marginLeft: 15,
            fontWeight: '600',
            color: '#222',
            fontSize: 18,
            width:170,
          },
          buttonContainer: {
              paddingVertical: 5,
              paddingHorizontal: 10,
              borderRadius: 10,
              textAlign: 'center',
              color: 'white',
              backgroundColor: "#169B62",
          },
          activeIndicatorContainer: {
            flexDirection: 'row',
            alignItems: 'center',
          },
          teammate:{
            fontSize:18,
            flex:1,
            color:"#696969",
            marginTop:5
          },
          user: {
            height:null,
            paddingTop:10,
            paddingBottom:10,
            backgroundColor: '#FFFFFF',
            flexDirection: 'column',
            borderTopWidth:40,
            marginBottom:20,
            width:'100%'
        },
        userContent:{
            flexDirection:'row',
            marginLeft:10, 
        },
        userimageContent:{
            marginTop:-40,
        },
        userimage:{
            width:100,
            height:100,
            borderRadius:50,
        },
        username:{
            fontSize:20,
            fontWeight: 'bold',
            marginLeft:10,
            alignSelf: 'center'
        }
      }); 

    return (      
        !events.length || !player || refreshing ? 
        <Loading /> : 
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>  

            <View style={[styles.user, {borderColor: '#169B62'}]}>
                
                <View style={styles.userContent}>
                    <TouchableOpacity onPress={() => setIsOpen(true)}>
                        <Image  style={[styles.userimage, styles.userimageContent]} source={{uri: player.img_src.concat('?time='+new Date())}}/>
                    </TouchableOpacity>
                  <Text style={styles.username}>
                      {player.name}{"\n"}
                      Total Pts: {score}
                    </Text>   
                    <TouchableOpacity style={{position:'absolute',right: 30, top:0}} onPress={refeshData} >
                        <FontAwesome name="refresh" size={30} color="#646464" style={{ marginLeft: 20, marginTop: 5}} />
                    </TouchableOpacity>      
                </View>
 
            </View>
          
            <FlatList 
                style={styles.tasks}
                data={events}
                keyExtractor= {(event) => event.id.toString()}
                renderItem={renderItem}
                refreshControl={<RefreshControl refreshing={refreshing} onRefesh={refeshData} />}
            />

        {isOpen && (
          <Lightbox
            mainSrc={player.img_src.concat('?time='+new Date())}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}
        </SafeAreaView>
    );
}
export default ScoreDetailsScreen;