import * as React from 'react';
import { SafeAreaView, Image } from 'react-native';

const Loading = (props) => {
    return (
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Image
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 216,
                    height: 216
                }}
                source={{
                uri: 'https://beerolympics.party/api/loading.gif',
            }}
            />
        </SafeAreaView>
    );
}
export default Loading;

