import React, { useState, useEffect } from 'react';
import { SafeAreaView, Text, StyleSheet, TouchableOpacity, Alert } from 'react-native';    
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';

const SettingScreen = (props) => {

    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const styles = StyleSheet.create({
        buttonContainer: {
            marginTop:10,
            height:45,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom:20,
            width:250,
            borderRadius:30,
            color: 'white',
            backgroundColor: "#169B62",
        },
        message: {
            color: 'green'
        },
        error: {
            color: 'red'
        }
      });

    const navigateTo = (page) => {
        props.navigation.navigate(page);
    }

    const createSchedule  = async () => {

        let response = await fetch('https://beerolympics.party/api/games.php', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'createSchedule'
                })
            });
    
        let results = await response.json();
            if(results.success){
                setMessage(results.message)
            } else {
                setError(results.message);
            }

    }

    const showConfirmDialog = () => {

        setMessage(null);
        setError(null);
        
        var r = confirm("Really create new schedule?");
        if (r == true) {
            createSchedule();
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        return () => {
            setError(null);
        }        
    },[]);


    return (
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>

           <TouchableOpacity style={styles.buttonContainer} onPress={() => navigateTo('Users')} >
               <Text style={{color: "#FFF", fontSize: 15}}><FontAwesome name="users" size={25} color="#FFF" />  Players </Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.buttonContainer} onPress={() => navigateTo('Set Current Heat')} >
               <Text style={{color: "#FFF", fontSize: 15}}><FontAwesome name="fire" size={25} color="#FFF" />  Heats </Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.buttonContainer} onPress={() => navigateTo('Set Game Type')} >
               <Text style={{color: "#FFF", fontSize: 15}}><FontAwesome5 name="cloud-sun-rain" size={25} color="#FFF" />  Games </Text>
            </TouchableOpacity>

            <TouchableOpacity style={[styles.buttonContainer, {backgroundColor: 'orange'}]} onPress={() => showConfirmDialog()} >
               <Text style={{color: "#FFF", fontSize: 15}}><FontAwesome5 name="calendar" size={25} color="#FFF" />  Schedule </Text>
            </TouchableOpacity>

            {message ? <Text style={styles.message} > { message } </Text> : null }
            {error ? <Text style={styles.error} > { error } </Text> : null }

        </SafeAreaView>
    );
}
export default SettingScreen;
