import React, { useState, useRef, useEffect, useContext } from 'react';
import { Context } from '../Context';
import { Text, SafeAreaView, TextInput, Pressable, Image, StyleSheet, View, TouchableOpacity } from 'react-native';
import { Camera } from 'expo-camera';
import { FontAwesome } from '@expo/vector-icons';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";


const UserScreen = (props) => {

    const {user, setUser} = useContext(Context);
    const [userId, setUserId] = useState(null);
    const [error, setError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const [email, setEmail] = useState("");
    const [pin, setPin] = useState("");

    const [imgUrl, setImgUrl] = useState('https://beerolympics.party/api/logo.png')

    const [hasPermission, setHasPermission] = useState(null);

    const camera = useRef(null);

    useEffect(() => {

        const fetchData = async () => {

            let response = await fetch('https://beerolympics.party/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'getUser',
                    id: userId
                })
            });

            const results = await response.json();
            setUser(results.user);
            setUserId(results.user.id);
            
        };

        if(userId){
            fetchData()
        }

    }, [userId, updateCount]);

    const signIn = () => {
        const fetchData = async () => {

            let response = await fetch('https://beerolympics.party/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'getUserWithPin',
                    email: email,
                    pin: pin
                })
            });

            const results = await response.json();
            if(!results.success){
                setError(results.message)
            } else {
                setUserId(results.user.id);
                setUser(results.user);
                sessionStorage.setItem('email', results.user.email);
            }
            
        };

        if(!email){
            setError("Really? You're just going to leave the email field blank?")
        } else {
            fetchData()
        }
        
    }

    

    useEffect(() => {

        const fetchData = async () => {

            let response = await fetch('https://beerolympics.party/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'getUser',
                    id: userId
                })
            });

            const results = await response.json();
            setUser(results.user);
            
        };

        if(userId){
            fetchData()
        }

    }, [userId]);

    const getCameraPermission = () => {

        (async () => {
            const { status } = await Camera.requestCameraPermissionsAsync();
            setHasPermission(status === 'granted');
          })();
        
    }

    const takeSelfie = async() => {

        setIsUploading(true);

        const options = {quality: 0.5};
        const photo = await camera.current.takePictureAsync(options);
      
        let localUri = photo.uri;
      
        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'updatePhoto',
                    id: user.id,
                    image: localUri
                })
        });

        const results = await response.json();
        setIsUploading(false);
        setHasPermission(false);
        setUpdateCount(updateCount + 1)

    }

    const styles = StyleSheet.create({
        header:{
            backgroundColor: "#169B62",
            height:200,
        },
        camera: {
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'center'
        },
        avatar: {
            width: 130,
            height: 130,
            borderRadius: 63,
            borderWidth: 4,
            borderColor: "white",
            marginBottom:10,
            alignSelf:'center',
            position: 'absolute',
            marginTop:-80
        },
        name:{
            fontSize:22,
            color:"#FFFFFF",
            fontWeight:'600',
        },
        body:{
            marginTop:40,
        },
        bodyContent: {
            flex: 1,
            alignItems: 'center',
            padding:30,
        },
        name:{
            fontSize:28,
            color: "#696969",
            fontWeight: "600"
        },
        buttonContainer: {
            marginTop:10,
            height:45,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom:20,
            width:250,
            borderRadius:30,
            color: 'white',
            backgroundColor: "#169B62",
        },
        input: {
            fontSize: 17,
            backgroundColor: 'white',
            marginBottom: 10,
            marginTop: 10,
            borderRadius: 10,
            width: '80%',
            padding: 9
        }
    });

    const logOut = () => {
        setUser({
            id: null,
            name: null,
            email: null,
            img_src: null,
            admin: 0
          })

          setEmail(null);
          setPin(null);
          sessionStorage.clear();
    }

    const swapImage = () => {
        if(imgUrl == 'https://beerolympics.party/api/logo.png'){
            setImgUrl('https://beerolympics.party/api/logo2.png');
        } else if(imgUrl == 'https://beerolympics.party/api/logo2.png') {
            setImgUrl('https://beerolympics.party/api/logo3.png');
        } else {
            setImgUrl('https://beerolympics.party/api/logo.png');
        }
    }

    return (
        hasPermission ?
        <SafeAreaView style={{ flex: 1 }}>
            <Camera ref={camera} style={styles.camera} type={Camera.Constants.Type.front}>
                <Text style={{position: 'absolute', right: 25, top: 20}} onPress={() => setHasPermission(false)} >
                    <FontAwesome name="close" size={25} color="#169B62" />
                </Text>
                
                <Pressable onPress={() => takeSelfie()} style={[styles.buttonContainer,{position: 'absolute', bottom: 0, width: '100%'}]}> 
                {isUploading ? 
                    <Text style={{color: '#fff'}}>Uploading Hideous Image ...</Text> :
                    <Text style={{color: '#fff'}}>Take Selfie</Text>}
                </Pressable>                
                
            </Camera>
        </SafeAreaView> :
        user.id ? 
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <View style={styles.container}>
                <View style={styles.header}></View>
                <TouchableOpacity onPress={() => setIsOpen(true)}>
                    <Image style={styles.avatar} source={{uri: user.img_src.concat('?time='+new Date())}}/>
                </TouchableOpacity>                
                <View style={styles.body}>
                    <View style={styles.bodyContent}>
                        <Text style={styles.name}>{ user.name }</Text>
                        <TouchableOpacity style={styles.buttonContainer} onPress={getCameraPermission}>
                            <Text style={{color: 'white'}}>Update Image</Text>  
                        </TouchableOpacity>              
                        <TouchableOpacity style={styles.buttonContainer} onPress={logOut}>
                            <Text style={{color: 'white'}}>Log Out</Text> 
                        </TouchableOpacity>
                    </View>
                </View>

                {isOpen && (
                    <Lightbox
                        mainSrc={user.img_src.concat('?time='+new Date())}
                        onCloseRequest={() => setIsOpen(false)}
                    />
                )}
            </View>
        </SafeAreaView>
         : 
         <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Pressable onPress={() => swapImage()} >
            <Image
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 200,
                    height: 200
                }}
                source={{
                uri: imgUrl,
            }}
            />
            </Pressable>
            <Text style={{ width: '80%', color: 'red'}}>{ error }</Text>
            <TextInput 
                style={styles.input}
                keyboardType="email-address"
                returnKeyType="done"
                returnKeyLabel="done"
                placeholder="Email" 
                autoCapitalize="none"
                value={email}
                onFocus={()=> setError(null)}
                onChangeText={text => setEmail(text)}
            />

            <TextInput 
                style={styles.input}
                keyboardType="number-pad"
                returnKeyType="done"
                returnKeyLabel="done"
                placeholder="PIN" 
                autoCapitalize="none"
                maxLength={4}
                value={pin}
                onFocus={()=> setError(null)}
                onChangeText={text => setPin(text)}
            />
            <TouchableOpacity style={styles.buttonContainer} onPress={signIn}>
                <Text style={{color: 'white'}}>Sign In</Text> 
            </TouchableOpacity>
        </SafeAreaView>
    );
}
export default UserScreen;
