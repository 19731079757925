import React, { useState, useEffect } from 'react';
import { SafeAreaView, Text, StyleSheet, View, Image, TextInput, TouchableOpacity, Switch } from 'react-native';
import Loading from '../../elements/Loading';
import { useIsFocused } from "@react-navigation/native";

const EditUserScreen = (props) => {

    const [id, setId] = useState(props.route.params.id);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    //form stuff
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [allowImage, setAllowImage] = useState("");
    const [active, setActive] = useState(true);

    const isFocused = useIsFocused();

    const updateUser = async () => {

        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'editUser',
                id: id,
                name: name,
                email: email,
                allow_image: allowImage ? 1 : 0,
                active: active ? 1: 0
            })
        });

        const results = await response.json();
        if(!results.success){
            setError(results.message);
        } else {
            props.navigation.goBack();
        }
        
    };
        

    useEffect(() => {

        const fetchData = async () => {

            let response;
            let results;
    
            response = await fetch('https://beerolympics.party/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'getUser',
                    id: id
                })
            });
    
            results = await response.json();
            setUser(results.user);
            setName(results.user.name);
            setEmail(results.user.email);
            setAllowImage(results.user.allow_image);
            setActive(parseInt(results.user.active) ? true : false)
           
        };

        fetchData();

        return () => {
            setUser(null); 
        }
    }, [isFocused]);

    const styles = StyleSheet.create({
        user: {
            height:null,
            paddingTop:10,
            paddingBottom:5,
            marginTop:5,
            backgroundColor: '#FFFFFF',
            flexDirection: 'column',
            borderTopWidth:40,
            marginBottom:20,
            width:'100%'
        },
        userContent:{
            flexDirection:'row',
            marginLeft:10, 
        },
        userimageContent:{
            marginTop:-40,
        },
        userimage:{
            width:100,
            height:100,
            borderRadius:50,
        },
        username:{
            fontSize:20,
            fontWeight: 'bold',
            marginLeft:10,
            alignSelf: 'center'
        },
        input: {
            borderRadius: 5, 
            borderWidth: 1, 
            borderColor: '#169B62', 
            marginBottom: 10,
            backgroundColor: '#FFF',
            padding: 5
        },
        buttonContainer: {
            marginTop:15,
            height:45,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom:20,
            width:250,
            borderRadius:30,
            color: 'white',
            backgroundColor: "#169B62",
        },
      });

    const onSubmit = (e) => {
        e.preventDefault();
        updateUser();
    }

    return (
        !user ? <Loading /> : 
        <SafeAreaView style={{ flex: 1, alignItems: 'center' }}>

            <View style={[styles.user, {borderColor: '#169B62'}]}>
                <View style={styles.userContent}>
                    <Image style={[styles.userimage, styles.userimageContent]} source={{uri: user.img_src.concat('?time='+new Date())}}/>
                    <Text style={styles.username}>{user.name}</Text>           
                </View> 
            </View>

            <View style={{flex: 1}} >
                <Text style={{ width: '80%', color: 'red', fontSize: 18, marginTop: 8}}>{ error }</Text>
                <Text>Name:</Text>
                <TextInput style={styles.input} onChangeText={value => setName(value)} value={name} />    

                <Text>Email:</Text>
                <TextInput style={styles.input} onChangeText={value => setEmail(value)} value={email} />
                
                <Text style={styles.input}>{user.pin}</Text>
                

                <Text>{allowImage ? "Image Allowed" : "Image Blocked"} :</Text>
                <Switch
                    trackColor={{ false: '#DCDCDC', true: '#169B62' }}
                    thumbColor={allowImage ? '#169B62' : '#DCDCDC'}
                    onValueChange={() => setAllowImage(!allowImage)}
                    style={{ marginTop: 10, transform: [{ scaleX: 1.1 }, { scaleY: 1.1 }] }}
                    value={allowImage ? true : false}
                />

                <Text>{active ? "Active" : "Inactive"} :</Text>
                <Switch
                    trackColor={{ false: '#DCDCDC', true: '#169B62' }}
                    thumbColor={active ? '#169B62' : '#DCDCDC'}
                    onValueChange={() => setActive(!active)}
                    style={{ marginTop: 10, transform: [{ scaleX: 1.1 }, { scaleY: 1.1 }] }}
                    value={active ? true : false}
                />

                <TouchableOpacity style={styles.buttonContainer} onPress={(e) => onSubmit(e)} >
                    <Text style={{color: '#FFF'}}> Update User </Text>
                </TouchableOpacity>

            </View>

            
        </SafeAreaView>
    );
}
export default EditUserScreen;