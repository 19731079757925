import React, { useContext, useState, useEffect} from 'react';
import { Text, View, SafeAreaView, StyleSheet, TouchableOpacity, ScrollView, Image} from 'react-native';
import { Context } from '../Context';
import Loading from '../elements/Loading';
import { FontAwesome } from '@expo/vector-icons';
import { useIsFocused } from "@react-navigation/native";
import Markdown from 'react-native-easy-markdown';
import CountDown from 'react-native-countdown-component';
import { Audio } from 'expo-av';

const HeatScreen = (props) => {

    const {user, heat, endTime, setGameRunning} = useContext(Context);
    const [hold, setHold] = useState(false);
    const [event, setEvent] = useState(null);
    const [secondsUntilEnd, setSecondsUntilEnd] = useState(0);
    const [sound, setSound] = useState(null);

    const isFocused = useIsFocused();

    async function playSound() {
        const { sound } = await Audio.Sound.createAsync(
           require('./klaxon.mp3')
        );
        setSound(sound);
        await sound.playAsync(); 
      }
      
      useEffect(() => {
        return sound
          ? () => {
              sound.unloadAsync(); }
          : undefined;
      }, [sound]);

    const fetchData = async () => {

        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getHeatInformation',
                id: user.id,
                heat: heat
            })
        });

        const results = await response.json();
        if(results.message === "Waiting for next event"){
            setHold(true);
        } else {
            setHold(false);
            setEvent(results.event);
        }
        

    };

    useEffect(() => {
        fetchData();
        return () => {
            setEvent(null);
        }
    },[isFocused, heat])

    useEffect(() => {
        if(endTime){
            const now = new Date().valueOf();
            const time = endTime.split(":");
            const end = new Date().setHours(time[0],time[1],time[2]);
            let secondsLeftInHeat = (end - now) / 1000;
            if(secondsLeftInHeat === secondsUntilEnd){
                secondsLeftInHeat = secondsLeftInHeat + 1;
            }
            if(end > now){                     
                const newEndTime = new Date(secondsLeftInHeat * 1000).toISOString().substr(11, 8);
                console.log("NEW END TIME", newEndTime);
                console.log('Setting seconds left to ' + secondsLeftInHeat);
                setSecondsUntilEnd(secondsLeftInHeat);
                setGameRunning(true);
            } else {
                console.log('Setting seconds left to 0');
                setSecondsUntilEnd(0);
                setGameRunning(false);
            }
        } else {
            setSecondsUntilEnd(0);
            setGameRunning(null);
        }
        
    }, [isFocused, endTime]);


    const styles = StyleSheet.create({
        scrollView: {
            backgroundColor: 'white',
            padding:10,
            margin: 10
        },
        tasks:{
          width: '100%'
        },
        cardContent: {
          marginLeft:20,
          marginTop:10,
        },      
        card:{
          shadowColor: '#00000021',
          shadowOffset: {
            width: 0,
            height: 6,
          },
          shadowOpacity: 0.37,
          shadowRadius: 7.49,
          elevation: 12,   
          minHeight: 120,   
          maxHeight: 120,
          marginVertical: 10,
          marginHorizontal:20,
          backgroundColor:"white",
          flexBasis: '26%',
          padding: 10,
          flexDirection:'row',
          flexWrap: 'wrap',
          borderLeftWidth:6,
          flex: 1,
          justifyContent: 'space-between',
          height: 200
        },      
        description:{
          fontSize:20,
          flex:1,
          color:"#008080",
          fontWeight:'bold',
        },
        teammate:{
          fontSize:18,
          flex:1,
          color:"#696969",
          marginTop:5
        },
      }); 

    let color = '#169B62';

    const playKlaxon = (event) => {
        playSound();
    }

    return (
        hold ? 
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff'}}>
            <Image style={{width: 130,height: 130}} source={{uri: 'https://beerolympics.party/beerpong.gif'}}/>
            <Text> Waiting for event to begin ...</Text> 
        </SafeAreaView> :
        !event ? 
        <Loading />
        :
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>

            <View style={{height: 50, marginBottom: 5 }}>
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} >
                    
                         
                            <CountDown
                                id="counter"
                                until={secondsUntilEnd}
                                onFinish={() => setGameRunning(false)}
                                size={22}
                                digitStyle={{backgroundColor: '#169B62'}}
                                digitTxtStyle={{color: '#FFF'}}
                                timeToShow={['H','M', 'S']}
                                timeLabels={{h: '', m: '', s: ''}}
                            />
                   
                </View>                
            </View>
            
            <View>
                <TouchableOpacity style={[styles.card, {borderColor:color, marginTop: 5}] }  onPress={e => playKlaxon(event)}>
                    <Text style={{fontSize: 18,color:color}}><FontAwesome name="fire" size={25} color="#169B62" /> {heat}</Text>
                
                    <View style={styles.cardContent}>
                        <Text style={[styles.description, { color: color}]}>{event.event ? event.event : 'Bye - Take a Break!'}</Text>
                        <Text style={[styles.teammate, { color: color}]}>{event.event ? event.teammate : null}</Text>
                        <Text style={[styles.teammate,{ color: color}]}>{event.opponents ? 'vs. ' + event.opponents[0].concat(' & ',event.opponents[1]) : null }</Text>
                    </View>

                    <Text style={{fontSize: 18, fontWeight: 'bold',color: color}}> {event.score}</Text>
                </TouchableOpacity>
            </View>

            <View style={{height: 190, width: '99%'}}>
                <Text style={{fontSize: 18, fontWeight: 'bold',color: color, marginLeft: 8}}> Rules </Text>
                
                <ScrollView contentInsetAdjustmentBehavior="automatic" style={styles.scrollView}>
                    <Markdown markdownStyles={{text: {fontSize: 16}}}>{event.rules}</Markdown>                    
                </ScrollView>
            </View>

            <View style={{height: 150, width: '99%'}}>
                <Text style={{fontSize: 18, fontWeight: 'bold',color: color, marginLeft: 8}}> Scoring </Text>
                <ScrollView contentInsetAdjustmentBehavior="automatic" style={styles.scrollView}>
                    <Markdown markdownStyles={{text: {fontSize: 16}}}>{event.scoring}</Markdown>                    
                </ScrollView>
            </View>
        </SafeAreaView>
    );
}
export default HeatScreen;
