import React, { useState, useEffect } from 'react';
import { SafeAreaView, Text, StyleSheet, View, TextInput, TouchableOpacity } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import Loading from '../../elements/Loading';
import { useIsFocused } from "@react-navigation/native";

const EditHeatScreen = (props) => {

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    //form stuff
    const [heat, setHeat] = useState(0);
    const [endTime, setEndTime] = useState(null);


    const isFocused = useIsFocused();

    const updateHeat = async () => {

        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'updateHeat',
                heat: heat,
                time_end: endTime
            })
        });

        const results = await response.json();
        if(!results.success){
            setError(results.message);
        } else {
            setSuccess("Updated");
        }
        
    };
        

    useEffect(() => {

        const fetchData = async () => {

            let response;
            let results;
    
            response = await fetch('https://beerolympics.party/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'getCurrentHeat'
                })
            });
    
            results = await response.json();
            setHeat(parseInt(results.heat));
            setEndTime(results.end_time);
           
        };

        fetchData();

        return () => {
            setHeat(null); 
            setEndTime(null);
            setError(null)
            setSuccess(null);
        }
    }, [isFocused]);

    

    const styles = StyleSheet.create({
        input: {
            borderRadius: 5, 
            borderWidth: 1, 
            borderColor: '#169B62', 
            marginBottom: 10,
            marginBottom: 20,
            backgroundColor: '#FFF',
            padding: 5,
            fontSize: 19
        },
        buttonContainer: {
            marginTop:200,
            height:45,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom:20,
            width:250,
            borderRadius:30,
            color: 'white',
            backgroundColor: "#169B62",
        },
        heatContainer: {
            marginTop:3,
            height:40,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom:2,
            width:250,
            borderRadius:5,
            backgroundColor: "#169B62",
        },
      });

    const onSubmit = (e) => {
        e.preventDefault();
        updateHeat();
    }

    const resetView = () => {
        setError(null);
        setSuccess(null);
    }

    const setFifteenMin = () => {
        let now = new Date();
        console.log(now);
        var newEndTime = new Date(now.getTime() +  15.5 * 60000);
        newEndTime = newEndTime.getHours() + ":" + (newEndTime.getMinutes()<10?'0':'') + newEndTime.getMinutes() + ":" + newEndTime.getSeconds();
        setEndTime(newEndTime);        
    }

    return (
        (heat === null || !endTime) ? <Loading /> : 
        <SafeAreaView style={{ flex: 1, alignItems: 'center' }}>
            
            <View style={{flex: 1}} >
                <Text style={{ width: '80%', color: 'red', fontSize: 18, marginTop: 8}}>{ error }</Text>
                <Text style={{ width: '80%', color: 'green', fontSize: 18, marginTop: 8}}>{ success }</Text>
                <Text>Time End:</Text>
                <TextInput 
                    style={styles.input} 
                    onChangeText={value => setEndTime(value)} 
                    value={endTime} 
                    onFocus={resetView}
                />    
                <TouchableOpacity onPress={() => setFifteenMin()}> <Text>Set To 15 Min</Text></TouchableOpacity>

                <Picker
                    selectedValue={heat}
                    style={{ height: 50, width: 150, marginTop: 20 }}
                    onValueChange={(itemValue, itemIndex) => setHeat(itemValue)}
                    onFocus={resetView}
                >
                    {
                        [0,1,2,3,4,5,6,7,8,9,10].map(number => (
                            <Picker.Item key={number} label={"Heat " + number} value={number} />
                        ))
                    }
                </Picker>
                                
                <View>
                    <TouchableOpacity style={styles.buttonContainer} onPress={(e) => onSubmit(e)} >
                        <Text style={{color: '#FFF'}}> Update Heat </Text>
                    </TouchableOpacity>
                </View>

            </View>

            
        </SafeAreaView>
    );
}
export default EditHeatScreen;