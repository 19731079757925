import React, { useState, createContext } from "react";

export const Context = createContext();

export const Provider = props => {
  const initUser = {id: null, name: null, email: null, img_src: null, admin: 0}
  //const initUser = { id: 1, name: 'Sean Susko', email: 'sean.susk.com', img_src: 'https://beerolympics.party/api/user_img/1.jpg', admin: 1}
  const initHeat = { heat: null, time_end: null}

  const [user, setUser] = useState(initUser);
  const [heat, setHeat] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [gameRunning, setGameRunning] = useState(null);

  return (
    <Context.Provider value={{user, setUser, heat, setHeat, endTime, setEndTime, gameRunning, setGameRunning }}>
      {props.children}
    </Context.Provider>
  );
};