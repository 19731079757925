import React, {useRef, useContext, useState, useEffect } from 'react';
import { Text, SafeAreaView, TextInput, TouchableOpacity, Image } from 'react-native';
import { Context } from '../Context';
import { Audio } from 'expo-av';

const ScheduleDetailsScreen = (props) => {

    const event = props.route.params.event

    const {user} = useContext(Context);
    const [error, setError] = useState();
    const [storming, setStorming] = useState(false);
    const [sound, setSound] = useState(null);
    const ref = useRef();

    async function playSound() {
        const { sound } = await Audio.Sound.createAsync(
           require('./thunder.mp3')
        );
        setSound(sound);
        await sound.playAsync(); 
      }
      
      useEffect(() => {
        return sound
          ? () => {
              sound.unloadAsync(); }
          : undefined;
      }, [sound]);

    const submitScore = () => {
        const fetchData = async (score) => {

            let data = {
                function: 'updateScore',
                id: user.id,
                schedId: event.id,
                score: score
            }

            if(parseInt(user.admin)){
                data.admin = 1;
            }

            let response = await fetch('https://beerolympics.party/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const results = await response.json();
            if(!results.success){
                setError(results.message);
            } else {
                props.navigation.goBack();
            }
            
        };

        const score = ref.current.value;
        if(!score){
            setError("You must enter a valid number");
        } else {
            fetchData(score)
        }
        
    }

    const createStorm = () => {
        playSound();
        setStorming(true);
        setTimeout(function(){ setStorming(false) }, 3000);
    }


    return (
        event.event ? 
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>  
            {storming && 
                <Image
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 400,
                        height: 400,
                        position: 'absolute',
                        zIndex: 100000
                    }}
                    source={{
                    uri: 'https://beerolympics.party/api/lightening.png',
                }}
                />      
            }
            <Text style={{fontSize: 18}}>Enter Score For Heat {event.heat}</Text>
            <Text style={{fontSize: 18}}>{event.event} </Text>
            <Text style={{fontSize: 18}}>Partner: {event.teammate}</Text>
            <Text style={{ width: '80%', color: 'red', fontSize: 18, marginTop: 8}}>{ error }</Text>
            <TextInput 
                ref={ref} 
                style={{
                    fontSize: 17,
                    backgroundColor: 'white',
                    padding: 10,
                    marginBottom: 10,
                    marginTop: 40,
                    borderRadius: 10,
                    width: '15%',
                }}
                keyboardType="number-pad"
                returnKeyType="done"
                returnKeyLabel="done"
                autoCapitalize="none"
                maxLength={2}
            />
            <TouchableOpacity style={{
                marginTop:10,
                height:45,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom:20,
                width:250,
                borderRadius:30,
                color: 'white',
                backgroundColor: "#169B62",
            }} onPress={submitScore}>
                <Text style={{color: 'white'}}>Submit Score*</Text> 
            </TouchableOpacity>
            <TouchableOpacity onPress={e => createStorm()} >
                <Text style={{fontSize: 18, marginTop: 10}}> *Remember, God is watching you </Text>
            </TouchableOpacity>
        </SafeAreaView> :
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{fontSize: 18}}>No score recorded for a bye.</Text>
        </SafeAreaView>
    );
}
export default ScheduleDetailsScreen;