import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Context } from '../Context';
import { Text, SafeAreaView, View, TouchableOpacity, StyleSheet, FlatList, RefreshControl, Image} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useIsFocused } from "@react-navigation/native";
import Loading from '../elements/Loading';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";

const Schedule = (props) => {

    const {user, heat} = useContext(Context);
    const isFocused = useIsFocused();

    const [events, setEvents] = useState([]);
    const [score, setScore] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [isOpen, setIsOpen] = useState(false)

    const fetchData = async () => {

        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getEventInfo',
                id: user.id
            })
        });

        const results = await response.json();
        setScore(results.user.score);
        setEvents(results.user.events);
        setRefreshing(false);        
    };

    useEffect(() => {
        fetchData()
    }, [isFocused]);

    const refeshData = () => {
        setRefreshing(true);
        fetchData();
    };

    const openEvent = (event) => {
        if(heat >= event.heat){
            props.navigation.navigate('Details', { event: event })
        } else {
            alert("This heat has not yet begun")
        }     
    }

    const renderItem = ({item}) => {

        let isCurHeat = heat === item.heat

        let color = isCurHeat ? '#169B62' : (item.heat <=  heat ? 'grey' : 'black');
        let heatColor = isCurHeat ? '#169B62' : (item.heat <=  heat ? 'grey' : "#FF883E");

        return (
            <TouchableOpacity style={styles.row} onPress={e => openEvent(item)}>
                <Text style={{ fontSize: 20, color: heatColor, width: 50}}>
                    <FontAwesome name="fire" size={25} color={heatColor} /> 
                    {item.heat}
                </Text>
                <View>
                    <View style={styles.nameContainer}>
                        <Text style={[styles.nameTxt, {color: color, fontSize: 21, fontWeight: 'bold'}]} numberOfLines={1} ellipsizeMode="tail">
                            {item.event ? item.event : 'Bye'}
                        </Text>            
                    </View>
                    <View style={styles.nameContainer}>
                        <Text style={[styles.nameTxt, {color: color}]} numberOfLines={1} ellipsizeMode="tail">
                            {item.event ? item.teammate : null}
                        </Text>            
                    </View>
                    <View style={styles.nameContainer}>
                        <Text style={[styles.nameTxt, {color: color}]} numberOfLines={1} ellipsizeMode="tail">
                            {item.opponents ? 'vs. ' + item.opponents[0].concat(' & ',item.opponents[1]) : null }
                        </Text>            
                    </View>
                </View>
                <Text style={{fontSize: 18, fontWeight: 'bold',color: color}}> {item.score}</Text>
            </TouchableOpacity>
        );
      }

    const styles = StyleSheet.create({
        tasks:{
          flex:1,
          width: '100%'
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            borderColor: '#DCDCDC',
            backgroundColor: '#fff',
            padding: 5,
            minHeight: 90,
            marginBottom: 3
          },
          pic: {
            borderRadius: 30,
            width: 60,
            height: 60,
          },
          nameContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 265,
          },
          nameTxt: {
            marginLeft: 15,
            fontWeight: '600',
            color: '#222',
            fontSize: 18,
            width:220,
          },
          buttonContainer: {
              paddingVertical: 5,
              paddingHorizontal: 10,
              borderRadius: 10,
              textAlign: 'center',
              color: 'white',
              backgroundColor: "#169B62",
          },
          activeIndicatorContainer: {
            flexDirection: 'row',
            alignItems: 'center',
          },
          teammate:{
            fontSize:18,
            flex:1,
            color:"#696969",
            marginTop:5
          },
        user: {
            height:null,
            paddingTop:2,
            paddingBottom:2,
            backgroundColor: '#FFFFFF',
            flexDirection: 'column',
            borderTopWidth:40,
            marginBottom:20,
            width:'100%'
        },
        userContent:{
            flexDirection: 'row',
            marginLeft:10, 
        },
        userimageContent:{
            marginTop:-40,
        },
        userimage:{
            width:100,
            height:100,
            borderRadius:50,
        },
        username:{
            fontSize:20,
            fontWeight: 'bold',
            marginLeft:20
        }
      }); 

    return (      
        !events.length || refreshing ? 
        <Loading /> : 
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}>  
            
            <View style={[styles.user, {borderColor: '#169B62'}]}>
                <View style={styles.userContent}>
                    <TouchableOpacity onPress={() => setIsOpen(true)}>
                        <Image style={[styles.userimage, styles.userimageContent]} source={{uri: user.img_src.concat('?time='+new Date())}}/>
                    </TouchableOpacity>
                    <Text style={styles.username}>
                      {user.name}{"\n"}
                      Total Pts: {score}
                    </Text>   
                    <TouchableOpacity style={{position:'absolute',right: 30, top:0}} onPress={refeshData} >
                    <   FontAwesome name="refresh" size={30} color="#646464" style={{ marginLeft: 20, marginTop: 5}} />
                    </TouchableOpacity>             
                </View> 
            </View>

            <FlatList 
                style={styles.tasks}
                data={events}
                keyExtractor= {(event) => event.id.toString()}
                renderItem={renderItem}
                refreshControl={<RefreshControl refreshing={refreshing} onRefesh={refeshData} />}
            />

                {isOpen && (
                    <Lightbox
                        mainSrc={user.img_src.concat('?time='+new Date())}
                        onCloseRequest={() => setIsOpen(false)}
                    />
                )}
        </SafeAreaView>
    );
}
export default Schedule;