import React, { useContext, useEffect, useState} from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { Audio } from 'expo-av';

import UserScreen from './pages/UserScreen';
import ScheduleScreen from './pages/ScheduleScreen';
import ScheduleDetailsScreen from './pages/ScheduleDetailsScreen';
import ScoresScreen from './pages/ScoresScreen';
import ScoreDetailsScreen from './pages/ScoreDetailsScreen';
import HeatScreen from './pages/HeatScreen';
import PhotosScreen from './pages/PhotosScreen';

import SettingsScreen from './pages/_admin/SettingsScreen';
import UsersScreen from './pages/_admin/UsersScreen';
import EditUserScreen from './pages/_admin/EditUserScreen';
import EditHeatScreen from './pages/_admin/EditHeatScreen';
import EditGamesScreen from './pages/_admin/EditGamesScreen';

import { Context } from './Context';

const Tab = createBottomTabNavigator();

const ScheduleStack = createStackNavigator();

function UserStackScreen() {
  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen name="Me" component={UserScreen} />
    </ScheduleStack.Navigator>
  );
}

function ScheduleStackScreen() {
  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen name="Schedule" component={ScheduleScreen} />
      <ScheduleStack.Screen name="Details" component={ScheduleDetailsScreen} />
    </ScheduleStack.Navigator>
  );
}

function ScoresStackScreen() {
  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen name="Rankings" component={ScoresScreen} />
      <ScheduleStack.Screen name="Details" component={ScoreDetailsScreen} />
      <ScheduleStack.Screen name="Edit Score" component={ScheduleDetailsScreen} />
    </ScheduleStack.Navigator>
  );
}

function HeatStackScreen() {
  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen name="Now" component={HeatScreen} />
    </ScheduleStack.Navigator>
  );
}

function PhotosStackScreen() {
  return (
    <ScheduleStack.Navigator>
      <ScheduleStack.Screen name="Photos" component={PhotosScreen} />
    </ScheduleStack.Navigator>
  );
}

function SettingsStackScreen() {
  return (
    <ScheduleStack.Navigator screenOptions={{unmountOnBlur: true }}>
      <ScheduleStack.Screen name="Settings" component={SettingsScreen} />
      <ScheduleStack.Screen name="Users" component={UsersScreen} />
      <ScheduleStack.Screen name="Edit User" component={EditUserScreen} />
      <ScheduleStack.Screen name="Details" component={ScoreDetailsScreen} />
      <ScheduleStack.Screen name="Edit Score" component={ScheduleDetailsScreen} />
      <ScheduleStack.Screen name="Set Current Heat" component={EditHeatScreen} />
      <ScheduleStack.Screen name="Set Game Type" component={EditGamesScreen} />
    </ScheduleStack.Navigator>
  );
}

export default function Main() {

  const {user, setUser, heat, setHeat, setEndTime, gameRunning} = useContext(Context);
  const [sound, setSound] = useState(null);
  const [prevHeat, setPrevHeat] = useState(heat);
  const [prevGameRunning, setPrevGameRunning] = useState(gameRunning);
  const [userChecked, setUserChecked] = useState(false);

  async function playSound() {
    const { sound } = await Audio.Sound.createAsync(
       require('./pages/alarm.mp3')
    );
    setSound(sound);
    await sound.playAsync(); 
  }
  
  useEffect(() => {
    return sound
      ? () => {
          sound.unloadAsync(); }
      : undefined;
  }, [sound]);

  useEffect(() =>{
    console.log("HEAT CHANGED!",heat);
    if(heat !== 0 && prevHeat !== null){
      playSound();
    }    
    setPrevHeat(heat);
  }, [heat]);

  useEffect(() =>{
    console.log("GAME RUNNING CHANGED!",gameRunning);
    if(prevGameRunning !== null && gameRunning === false){
      playSound();
    }
    setPrevGameRunning(gameRunning);
  }, [gameRunning]);

  useEffect(() => {

      const fetchData = async (email) => {

        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getUserByEmail',
                email: email
            })
        });

        const results = await response.json();      
        setUser(results.user); 
        setUserChecked(true);
        
    };

    let email = sessionStorage.getItem('email');

    if(email){
      fetchData(email);
    } else {
      setUserChecked(true);
    }

  },[])

  useEffect(() => {

    const fetchData = async () => {

        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getCurrentHeat'
            })
        });

        const results = await response.json();        
        if(heat !== results.heat) {
          setHeat(results.heat);
          setEndTime(results.end_time);
        } 
        
    };

    const interval = setInterval(() => {
        fetchData()
    }, 5000);

    fetchData();
  }, []);

  return (
    userChecked ?
      <NavigationContainer>
        <Tab.Navigator
          screenOptions={({ route }) => ({
            unmountOnBlur: true,
            tabBarIcon: ({ focused, color, size }) => {
              let iconName;

              switch(route.name){
                case 'Me': iconName = focused ? 'user' : 'user-o'; break;
                case 'Schedule': iconName = focused ? 'calendar' : 'calendar-o'; break;
                case 'Rankings': iconName = focused ? 'list-ol' : 'list-ul'; break;
                case 'Now': iconName = focused ? 'clock-o' : 'clock-o'; break;
                case 'Settings': iconName = focused ? 'cogs' : 'cog'; break;
                case 'Photos' : iconName = focused ? 'camera' : 'camera'; break;
              }

              return <FontAwesome name={iconName} size={size} color={color} />;
            },
          })}
          tabBarOptions={{
            activeTintColor: '#FF883E',
            inactiveTintColor: '#169B62',
            animationEnabled: true
          }}
          
        >

          <Tab.Screen name="Me" component={UserStackScreen} />
          {user.id ? 
            <>
              <Tab.Screen name="Schedule" component={ScheduleStackScreen} />
              <Tab.Screen name="Rankings" component={ScoresStackScreen} />
              <Tab.Screen name="Now" component={HeatStackScreen} />
              <Tab.Screen name="Photos" component={PhotosStackScreen} />
            </> : null
          }     
          {parseInt(user.admin) ? 
              <Tab.Screen name="Settings" component={SettingsStackScreen} />
              : null
          }     
        </Tab.Navigator>
      </NavigationContainer> : null
  );
}