import React, { useState, useEffect} from 'react';
import { SafeAreaView, Text, StyleSheet, FlatList, Image, View, TouchableOpacity } from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import { FontAwesome } from '@expo/vector-icons';
import Loading from '../../elements/Loading';

const UsersScreen = (props) => {

    const isFocused = useIsFocused();

    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [active, setActive] = useState(0);
    const [refreshing, setRefreshing] = useState(false);

    const fetchData = async () => {

        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getUsers'
            })
        });

        const results = await response.json();
        setUsers(results.users);
        setTotal(results.total);
        setActive(results.active);
        setRefreshing(false);        
    };

    useEffect(() => {
        fetchData();

        return () => {
          setUsers(null);
          setTotal(0);
          setActive(0);
        }
    }, [isFocused]);

    const refeshData = () => {
      setUsers(null)
      setRefreshing(true);
      fetchData();
  };

    
    const navigateTo = (page, id) => {
        props.navigation.navigate(page,{id: id});
    }

    const styles = StyleSheet.create({
        row: {
          flexDirection: 'row',
          alignItems: 'center',
          borderColor: '#DCDCDC',
          backgroundColor: '#fff',
          borderBottomWidth: 1,
          padding: 10,
        },
        pic: {
          borderRadius: 30,
          width: 60,
          height: 60,
        },
        nameContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: 280,
        },
        nameTxt: {
          marginLeft: 15,
          fontWeight: '600',
          color: '#222',
          fontSize: 18,
          width:170,
        },
        buttonContainer: {
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderRadius: 10,
            textAlign: 'center',
            color: 'white',
            backgroundColor: "#169B62",
        },
        activeIndicatorContainer: {
          flexDirection: 'row',
          alignItems: 'center',
        },
        activeIndicatorText: {
          fontWeight: '400',
          color: 'red',
          fontSize: 13,
          marginLeft: 15,
        },
        activeIndicatorTextActive: {
            fontWeight: '400',
            color: 'green',
            fontSize: 13,
            marginLeft: 15,
          },
      });

    const renderItem = ({item}) => {
        return (
            <View style={styles.row}>
              <Image source={{ uri: item.img_src }} style={styles.pic} resizeMethod="resize" faseDuration={300}/>
              <View>
                <View style={styles.nameContainer}>
                  <Text style={styles.nameTxt} numberOfLines={1} ellipsizeMode="tail">{item.name}</Text>

                  <TouchableOpacity style={styles.buttonContainer} onPress={() => navigateTo('Edit User', item.id)}>
                    <FontAwesome name="user" size={25} color="#fff" />
                  </TouchableOpacity>

                  <TouchableOpacity style={styles.buttonContainer} onPress={() => navigateTo('Details', item.id)}>
                    <FontAwesome name="calendar" size={25} color="#fff" />
                  </TouchableOpacity>
                  
                </View>
                <View style={styles.activeIndicatorContainer}>
                  <Text style={parseInt(item.active) ? styles.activeIndicatorTextActive : styles.activeIndicatorText}>{parseInt(item.active) ? 'Active' : 'Inactive'}</Text>
                </View>
              </View>
            </View>
        );
      }


    return (
        !users ? <Loading /> :
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>

          <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize:19,color:"#151515",fontWeight: 'bold',paddingVertical: 10}}>
                    Active {active} / {total}                    
                </Text>        
                <TouchableOpacity onPress={refeshData} style={{ marginLeft: 20, marginTop: 10}} >
                    <FontAwesome name="refresh" size={30} color="#646464" />
                </TouchableOpacity>        
            </View>    
            <View style={{ flex: 1 }} >
                <FlatList 
                data={users}
                keyExtractor = {(user) => {
                    return user.id;
                }}
                renderItem={renderItem}/>
            </View>
           
        </SafeAreaView>
    );

    
      
}
export default UsersScreen;
