import React, { useState, useEffect, useContext, useRef } from 'react';
import { Context } from '../Context';
import { Text, SafeAreaView, View, TouchableOpacity, StyleSheet, FlatList, Image, Dimensions, Pressable} from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import Loading from '../elements/Loading';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import { FontAwesome } from '@expo/vector-icons';
import { Camera } from 'expo-camera';

const PhotosScreen = (props) => {

    const {user} = useContext(Context);
    const isFocused = useIsFocused();

    const [images, setImages] = useState(null);
    const [imageList, setImageList] = useState([]);
    const [updates, setUpdates] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [hasPermission, setHasPermission] = useState(null);
    const [cameraFace, setCameraFace] = useState('back');

    const camera = useRef(null);


    const fetchData = async () => {

        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getImages'
            })
        });

        const results = await response.json();
        setImages(results.images);
        const list = Object.keys(results.images).map((key) => results.images[key]['url']);
        list.reverse();
        setImageList(list);
        setRefreshing(false);   
        
    };

    const openImage = (id, url) => {

        if(parseInt(user.admin)){
            var r = confirm("Delete the photo?");
            if (r == true) {
                removeImage(url);
            } else {
                setPhotoIndex(id);
                setIsOpen(true);
            }
        } else {
            setPhotoIndex(id);
            setIsOpen(true);
        }
        
    }

    const { width, height } = Dimensions.get('screen');

    const renderItem = ({item}) => {

        return (
            
            <TouchableOpacity onPress={() => openImage(item.id, item.url)}>
                    <Image
                        style={{
                            width: (width - (.67 * width)),
                            height: 100
                        }}
                        source={{
                        uri: item.url,
                    }}
                    />
            </TouchableOpacity>
        );
      }


    useEffect(() => {
        
        fetchData();

        return () => {
            setImages(null)
            setUpdates(0);
            setRefreshing(false);
        }

    }, [isFocused, updates]);

    const refeshData = () => {
        setRefreshing(true);
        fetchData();
    };

    

    const styles = StyleSheet.create({
        tasks:{
            flex: 1, 
            flexDirection: 'row'
        },
        buttonContainer: {
            marginTop:10,
            height:45,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom:20,
            width:250,
            borderRadius:30,
            color: 'white',
            backgroundColor: "#169B62",
        },
        camera: {
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'center'
        },
        userContent:{
            flexDirection: 'row',
            marginLeft:10, 
            justifyContent: 'space-between'
        },
      }); 

    const getCameraPermission = () => {

        (async () => {
            const { status } = await Camera.requestCameraPermissionsAsync();
            setHasPermission(status === 'granted');
          })();
        
    }

      const takePhoto = async() => {

        setIsUploading(true);

        const options = {quality: 0.5};
        const photo = await camera.current.takePictureAsync(options);
      
        let localUri = photo.uri;
      
        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'addPhoto',
                    id: user.id,
                    image: localUri
                })
        });

        const results = await response.json();
        setIsUploading(false);
        setHasPermission(false);
        setUpdates(updates + 1)

    }

    const removeImage = async (url) => {

        var filename = url.substring(url.lastIndexOf('/')+1);

        if(user.admin){

            let response = await fetch('https://beerolympics.party/api/', {
                method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        function: 'removePhoto',
                        file: filename
                    })
            });

            const results = await response.json();
            if(results.success){
                setUpdates(updates + 1)
            } else {
                alert('Image could not be deleted')
                setUpdates(updates + 1)
            }
        } else {
            return false;
        }
    }

    const flipCamera = () => {
        setCameraFace(cameraFace === 'back' ? 'front' : 'back');
    }
  

    return (
        hasPermission ?
        <SafeAreaView style={{ flex: 1 }}>
            <Camera ref={camera} style={styles.camera} type={cameraFace === 'back' ? Camera.Constants.Type.back : Camera.Constants.Type.front}>
                <Text style={{position: 'absolute', left: 25, top: 20, color:"#169B62", fontWeight: 800}} onPress={() => flipCamera()} > FLIP </Text>
                <Text style={{position: 'absolute', right: 25, top: 20}} onPress={() => setHasPermission(false)} >
                    <FontAwesome name="close" size={25} color="#169B62" />
                </Text>
                
                <Pressable onPress={() => takePhoto()} style={[styles.buttonContainer,{position: 'absolute', bottom: 0, width: '100%'}]}> 
                {isUploading ? 
                    <Text style={{color: '#fff'}}>Uploading Image ...</Text> :
                    <Text style={{color: '#fff'}}>Take Photo</Text>}
                </Pressable>                
                
            </Camera>
        </SafeAreaView> :
        !images || refreshing ? 
        <Loading /> :         
        <SafeAreaView style={{ flex: 1, justifyContent: 'flex-start'}}>   

            <View style={styles.userContent}>
                <TouchableOpacity style={styles.buttonContainer} onPress={getCameraPermission}>
                        <Text style={{color: 'white'}}>Add Photo</Text>  
                    </TouchableOpacity> 
                <TouchableOpacity onPress={refeshData} >
                    <FontAwesome name="refresh" size={30} color="#646464" style={{ marginRight: 20, marginTop: 20}} />
                </TouchableOpacity>              
            </View>    
            
            <FlatList 
                columnWrapperStyle={{justifyContent: 'space-between'}}
                numColumns={3}
                style={styles.tasks}
                data={images}
                keyExtractor= {(image) => image.id}
                renderItem={renderItem}
            />

            {isOpen && (
                <Lightbox
                    mainSrc={imageList[photoIndex]}
                    nextSrc={imageList[(photoIndex + 1) % imageList.length]}
                    prevSrc={imageList[(photoIndex + imageList.length - 1) % imageList.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + imageList.length - 1) % imageList.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imageList.length)}
                />
            )}
        </SafeAreaView>
    );
}
export default PhotosScreen;
