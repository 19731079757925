import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../Context';
import { Text, SafeAreaView, View, TouchableOpacity, StyleSheet, FlatList, RefreshControl } from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import Loading from '../elements/Loading';

const ScoresScreen = (props) => {

    const {user} = useContext(Context);
    const isFocused = useIsFocused();

    const [stats, setStats] = useState(null);
    const [updates, setUpdates] = useState(0);
    const [place, setPlace] = useState(null);
    const [refreshing, setRefreshing] = useState(false);

    const fetchData = async () => {

        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'getStats'
            })
        });

        const results = await response.json();
        setStats(results.scores);
        var userData = results.scores.filter(obj => {
            return parseInt(obj.id) === user.id;
        })
        if(userData && userData[0]){
            setPlace(userData[0].place_alt)
        }
        setRefreshing(false);   
        
    };

    const openPlayerSchedule = (id) => {
        if(id != user.id){
            props.navigation.navigate('Details', { id: id })
        } else {
            props.navigation.navigate('Schedule');
        }        
    }

    const renderItem = ({item}) => {

        let iconColor;
        switch(item.place){
            case 1: iconColor = "gold"; break;
            case 2: iconColor = "#C0C0C0"; break;
            case 3: iconColor = "#cd7f32"; break;
            default: iconColor = "lightgrey";
        }

        return (
            <TouchableOpacity style={[styles.row, {borderColor: iconColor}]}  onPress={e => openPlayerSchedule(item.id)}>
                <Text style={{width: 40, fontSize: 14}}><FontAwesome5 name="medal" size={25} color={iconColor} /> {item.place_alt}</Text>
                <View>
                    <View style={styles.nameContainer}>
                        <Text style={[styles.nameTxt, {fontSize: 21, fontWeight: 'bold',color: 'grey'}]} numberOfLines={1} ellipsizeMode="tail">
                            {item.name}
                        </Text>            
                    </View>
                    <View style={styles.nameContainer}>
                        <Text style={[styles.nameTxt, {color: 'grey'}]} numberOfLines={1} ellipsizeMode="tail">
                            Games Played: {item.played}
                        </Text>            
                    </View>
                    <View style={styles.nameContainer}>
                        <Text style={[styles.nameTxt, {width: '100%', color: 'grey'}]} numberOfLines={1} ellipsizeMode="tail">
                            Points: {item.points}
                        </Text>            
                    </View>
                </View>
                <Text style={{fontSize: 18, fontWeight: 'bold'}}> {item.score}</Text>
            </TouchableOpacity>
        );
      }


    useEffect(() => {
        
        fetchData();

        return () => {
            setStats(null);
            setUpdates(0);
            setRefreshing(false);
        }

    }, [isFocused, updates]);

    const refeshData = () => {
        setRefreshing(true);
        fetchData();
    };

    const styles = StyleSheet.create({
        tasks:{
          flex:1,
          width: '100%'
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            borderColor: '#DCDCDC',
            backgroundColor: '#fff',
            borderBottomWidth: 1,
            padding: 5,
            minHeight: 90,
            marginBottom: 3
          },
          pic: {
            borderRadius: 30,
            width: 60,
            height: 60,
          },
          nameContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 250,
          },
          nameTxt: {
            marginLeft: 15,
            fontWeight: '600',
            color: '#222',
            fontSize: 18,
            width:170,
          },
          buttonContainer: {
              paddingVertical: 5,
              paddingHorizontal: 10,
              borderRadius: 10,
              textAlign: 'center',
              color: 'white',
              backgroundColor: "#169B62",
          },
          activeIndicatorContainer: {
            flexDirection: 'row',
            alignItems: 'center',
          },
          teammate:{
            fontSize:18,
            flex:1,
            color:"#696969",
            marginTop:5
          },
          userContent:{
            flexDirection: 'row',
            marginLeft:10, 
            justifyContent: 'space-between',
            height: 40
        },
      }); 

    return (
        !stats || refreshing ? 
        <Loading /> :         
        <SafeAreaView style={{ flex: 1, justifyContent: 'flex-start'}}>   
            <View style={styles.userContent}>
                <Text style={{fontSize:19,color:"#151515",fontWeight: 'bold',marginRight: 5, marginTop: 5}}>
                    Your Place: {place}
                </Text>    
                <TouchableOpacity onPress={refeshData} >
                    <FontAwesome name="refresh" size={30} color="#646464" style={{ marginRight: 20, marginTop: 5}} />
                </TouchableOpacity>            
            </View>    
            
            <FlatList 
                style={styles.tasks}
                data={stats}
                keyExtractor= {(stat) => stat.id}
                renderItem={renderItem}
                refreshControl={<RefreshControl refreshing={refreshing} onRefesh={refeshData} />}
            />
        </SafeAreaView>
    );
}
export default ScoresScreen;
