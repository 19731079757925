import React, { useState, useEffect } from 'react';
import { SafeAreaView, Text, StyleSheet, View, TouchableOpacity } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import Loading from '../../elements/Loading';
import { useIsFocused } from "@react-navigation/native";

const EditGamesScreen = (props) => {

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    //form stuff
    const [type, setType] = useState('outdoor');

    const isFocused = useIsFocused();

    const updateType = async () => {

        let response = await fetch('https://beerolympics.party/api/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                function: 'updateGameType',
                type: type
            })
        });

        const results = await response.json();
        if(!results.success){
            setError(results.message);
        } else {
            setSuccess("Updated");
        }
        
    };
        

    useEffect(() => {

        const fetchData = async () => {

            let response;
            let results;
    
            response = await fetch('https://beerolympics.party/api/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    function: 'getGameType'
                })
            });
    
            results = await response.json();
            console.log(results.type)
            setType(results.type);
           
        };

        fetchData();

        return () => {
            setType(null);
            setError(null)
            setSuccess(null);
        }
    }, [isFocused]);

    

    const styles = StyleSheet.create({
        input: {
            borderRadius: 5, 
            borderWidth: 1, 
            borderColor: '#169B62', 
            marginBottom: 10,
            backgroundColor: '#FFF',
            padding: 5
        },
        buttonContainer: {
            marginTop:200,
            height:45,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom:20,
            width:250,
            borderRadius:30,
            color: 'white',
            backgroundColor: "#169B62",
        },
        heatContainer: {
            marginTop:3,
            height:40,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom:2,
            width:250,
            borderRadius:5,
            backgroundColor: "#169B62",
        },
      });

    const onSubmit = (e) => {
        e.preventDefault();
        updateType();
    }

    const data = [
        {
          label: '1',
          accessibilityLabel: 'Heat 1'
         },
         {
          label: '2',
          accessibilityLabel: 'Heat 2'
         }
    ];

    const resetView = () => {
        setError(null);
        setSuccess(null);
    }

    return (
        !type ? <Loading /> : 
        <SafeAreaView style={{ flex: 1, alignItems: 'center' }}>
            
            <View style={{flex: 1}} >
                <Text style={{ width: '80%', color: 'red', fontSize: 18, marginTop: 8}}>{ error }</Text>
                <Text style={{ width: '80%', color: 'green', fontSize: 18, marginTop: 8}}>{ success }</Text>
                <Text>Game Type:</Text>    
                <Picker
                    selectedValue={type}
                    style={{ height: 50, width: 150 }}
                    onValueChange={(itemValue, itemIndex) => setType(itemValue)}
                    onFocus={resetView}
                >
                    {
                        ['indoor','outdoor'].map(type => (
                            <Picker.Item key={type} label={type} value={type} />
                        ))
                    }
                </Picker>
                                
                <View>
                    <TouchableOpacity style={styles.buttonContainer} onPress={(e) => onSubmit(e)} >
                        <Text style={{color: '#FFF'}}> Update Game Type </Text>
                    </TouchableOpacity>
                </View>

            </View>

            
        </SafeAreaView>
    );
}
export default EditGamesScreen;