import React from 'react';
import Main from './Main';
import { Provider } from './Context'

export default function App() {

  if (location.hostname !== "localhost" && location.protocol !== 'https:') {
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
  }

  return (
    <Provider>
      <Main />
    </Provider>
  );
}